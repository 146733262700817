export type CodeDetails = {
  valid: boolean;
  code: string;
  email: string;
  name: string;
  message: string;
  availableAmount: number;
  originalAmount: number;
  themeId: string;
  cardMessage: string;
  cardImageUrl: string;
  cardFrom: string;
  cardTo: string;
  spendCompleted: boolean;
};

export const DefaultCodeDetails: CodeDetails = {
  valid: false,
  code: "",
  email: "",
  name: "",
  message: "",
  availableAmount: 0,
  originalAmount: 0,
  themeId: "00000000-0000-0000-0000-000000000000",
  cardMessage: "",
  cardImageUrl: "",
  cardFrom: "",
  cardTo: "",
  spendCompleted: false,
};
