import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  LinearProgress,
  IconButton,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CodeDetails } from "../../../shared/types";
import {
  isCodeValid,
  getCodeErrors,
  isNameValid,
  getNameErrors,
  isEmailValid,
  getEmailErrors,
} from "../../../shared/utilities/validators";
import { RequiredTextField } from "../components";

export interface CodeDialogProps {
  busy: boolean;
  mobile: boolean;
  codeDetails: CodeDetails;
  handleContinue: (codeDetails: CodeDetails) => void;
  handleClose: () => void;
}

export const CodeDialog = ({
  busy,
  mobile,
  codeDetails,
  handleContinue,
  handleClose,
}: CodeDialogProps): JSX.Element => {
  const [updatedCodeDetails, setUpdatedCodeDetails] = useState<CodeDetails>({
    ...codeDetails,
  });

  useEffect(() => {
    setUpdatedCodeDetails({
      ...codeDetails,
    });
  }, [codeDetails]);

  return (
    <Dialog open={true} onClose={handleClose} fullScreen={mobile}>
      <DialogTitle>
        <div style={{ paddingTop: "20px" }}>
          Spend Your TisBest Charity Gift Card
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "3px",
            top: "3px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <RequiredTextField
              disabled={busy}
              label="Charity Gift Card Code"
              value={updatedCodeDetails.code}
              variant="dialog"
              test={(value: string) => {
                return getCodeErrors(value);
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let newCode = event.target.value;
                newCode = newCode.replace(/[^0-9a-zA-Z]/g, "");
                if (newCode.length == 3) {
                  let previousValue = updatedCodeDetails.code.replace(
                    /[^0-9a-zA-Z]/g,
                    ""
                  );
                  if (newCode.length > previousValue.length) {
                    newCode = newCode.slice(0, 3) + "-" + newCode.slice(3);
                  }
                } else if (newCode.length > 3) {
                  newCode = newCode.slice(0, 3) + "-" + newCode.slice(3);
                }
                setUpdatedCodeDetails({
                  ...updatedCodeDetails,
                  code: newCode.toUpperCase(),
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RequiredTextField
              autoComplete="name"
              disabled={busy}
              label="Name"
              value={updatedCodeDetails.name}
              variant="dialog"
              test={(value: string) => {
                return getNameErrors(value);
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUpdatedCodeDetails({
                  ...updatedCodeDetails,
                  name: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RequiredTextField
              autoComplete="email"
              disabled={busy}
              label="Email Address"
              helperText="We'll use this to inform you about the progress of your donation."
              value={updatedCodeDetails.email}
              variant="dialog"
              test={(value: string) => {
                return getEmailErrors(value);
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUpdatedCodeDetails({
                  ...updatedCodeDetails,
                  email: event.target.value.toLowerCase(),
                });
              }}
            />
          </Grid>
        </Grid>
        {mobile && (
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              onClick={() => {
                handleContinue(updatedCodeDetails);
              }}
              disabled={
                busy ||
                !isCodeValid(updatedCodeDetails.code) ||
                !isNameValid(updatedCodeDetails.name) ||
                !isEmailValid(updatedCodeDetails.email)
              }
              variant="outlined"
              style={{ marginTop: "24px" }}
            >
              Continue
            </Button>
          </Grid>
        )}
        {mobile && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{
                textAlign: "right",
                paddingRight: "24px",
              }}
            >
              By selecting continue you're agreeing to our{" "}
              <Link href="/charity-gift-card-terms" target="_blank">
                terms
              </Link>
              .
            </Typography>
          </Grid>
        )}
      </DialogContent>
      {!mobile && (
        <DialogActions sx={{ paddingRight: "24px", paddingBottom: "6px" }}>
          <Button
            onClick={() => {
              handleContinue(updatedCodeDetails);
            }}
            disabled={
              busy ||
              !isCodeValid(updatedCodeDetails.code) ||
              !isNameValid(updatedCodeDetails.name) ||
              !isEmailValid(updatedCodeDetails.email)
            }
            variant="outlined"
          >
            Continue
          </Button>
        </DialogActions>
      )}
      {!mobile && (
        <Typography
          variant="caption"
          sx={{
            textAlign: "right",
            paddingBottom: "16px",
            paddingRight: "24px",
          }}
        >
          By selecting continue you're agreeing to our{" "}
          <Link href="/charity-gift-card-terms" target="_blank">
            terms
          </Link>
          .
        </Typography>
      )}
      {busy && <LinearProgress />}
    </Dialog>
  );
};
