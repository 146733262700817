import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { Settings, CodeDetails } from "../../../shared/types";

export interface SpentDialogProps {
  settings: Settings;
  codeDetails: CodeDetails;
  busy: boolean;
  mobile: boolean;
  handleContinue: () => void;
}

export const SpentDialog = ({
  settings,
  codeDetails,
  busy,
  mobile,
  handleContinue,
}: SpentDialogProps): JSX.Element => {
  const renderSupport = () => {
    return (
      <React.Fragment>
        {" "}
        If you have any questions or concerns please contact our customer
        support team at{" "}
        <Link
          href={`mailto:${
            settings.supportEmail ? settings.supportEmail : "info@tisbest.org"
          }`}
        >
          {settings.supportEmail ? settings.supportEmail : "info@tisbest.org"}
        </Link>
        {settings.supportPhone ? (
          <React.Fragment>
            {" "}
            or call us at{" "}
            <Link href={`tel:${settings.supportPhone.replace(/\D/g, "")}`}>
              {settings.supportPhone}
            </Link>
            .
          </React.Fragment>
        ) : (
          "."
        )}
      </React.Fragment>
    );
  };

  return (
    <Dialog open={true} onClose={handleContinue} fullScreen={mobile}>
      <DialogTitle>
        <div style={{ paddingTop: "20px" }}>No Funds Remaining</div>
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" component="div">
              The code {codeDetails.code} has already been used.
              {renderSupport()}
            </Typography>
          </Grid>
          {mobile && (
            <Grid item xs={12}>
              {/* <Button
                onClick={handleContinue}
                disabled={busy}
                fullWidth={true}
                style={{ marginTop: "24px" }}
              >
                View Gift
              </Button> */}
              <Button
                onClick={handleContinue}
                disabled={busy}
                fullWidth={true}
                style={{ marginTop: "24px" }}
              >
                Close
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!mobile && (
        <DialogActions sx={{ paddingRight: "24px", paddingBottom: "24px" }}>
          {/* <Button onClick={handleContinue} disabled={busy}>
            View Gift
          </Button> */}
          <Button onClick={handleContinue} disabled={busy}>
            Close
          </Button>
        </DialogActions>
      )}
      {busy && <LinearProgress />}
    </Dialog>
  );
};
