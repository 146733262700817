import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom/client";
import { App } from "./pages";

if (process.env.NODE_ENV === "development") {
  console.log("TB-CODEVALIDATOR: Loading");
}
let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("TB-CODEVALIDATOR: Initializing React component");
  }
  let app = document.getElementById("tbcodevalidator");
  if (app) {
    const root = ReactDOM.createRoot(app);
    root.render(<App />);
  } else {
    if (process.env.NODE_ENV === "development") {
      console.log("TB-CODEVALIDATOR: No element to insert component into");
    }
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("TB-CODEVALIDATOR: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}
