import { convertToCurrency } from "../utilities/tools";
import { Donation, Settings } from "../types";

export const getDonationAmountErrors = (
  amount: number,
  donationMinimum: number
): string => {
  if (amount < donationMinimum) {
    return `The minimum donation amount is ${convertToCurrency(
      donationMinimum
    )}`;
  } else {
    return "";
  }
};

export const getCodeErrors = (code: string): string => {
  if (code.length == 0) {
    return "Charity Gift Card Code is required";
  } else if (code.length != 7) {
    return "Charity Gift Card Code format is invalid";
  } else {
    return "";
  }
};

export const getStreetErrors = (street: string): string => {
  if (street.length == 0) {
    return "Street is required";
  } else {
    return "";
  }
};

export const getCityErrors = (city: string): string => {
  if (city.length == 0) {
    return "City is required";
  } else {
    return "";
  }
};

export const getStateErrors = (state: string): string => {
  if (state.length == 0) {
    return "State is required";
  } else {
    return "";
  }
};

export const getZipErrors = (zip: string): string => {
  if (zip.length == 0) {
    return "Zip is required";
  } else {
    return "";
  }
};

export const getNameErrors = (name: string): string => {
  if (name.length == 0) {
    return "Name is required";
  } else {
    return "";
  }
};

export const getAttributionErrors = (attribution: string): string => {
  if (attribution.length == 0) {
    return "Attribution is required";
  } else {
    return "";
  }
};

export const getDesignationErrors = (designation: string): string => {
  if (designation.length == 0) {
    return "Designation is required";
  } else {
    return "";
  }
};

export const getPhoneErrors = (phone: string): string => {
  if (phone.length == 0) {
    return "Phone is required";
  } else {
    return "";
  }
};

export const getEmailErrors = (email: string): string => {
  if (email.length == 0) {
    return "Email is required";
  } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)) {
    return "Email format is invalid";
  } else {
    return "";
  }
};

export const isDonationAmountValid = (
  amount: number,
  donationMinimum: number
): boolean => {
  if (amount < donationMinimum) {
    return false;
  } else {
    return true;
  }
};

export const isCodeValid = (code: string): boolean => {
  if (code.length == 0) {
    return false;
  } else if (code.length != 7) {
    return false;
  } else {
    return true;
  }
};

export const isNameValid = (name: string): boolean => {
  if (name.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isStreetValid = (street: string): boolean => {
  if (street.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isCityValid = (city: string): boolean => {
  if (city.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isStateValid = (state: string): boolean => {
  if (state.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isZipValid = (zip: string): boolean => {
  if (zip.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isAttributionValid = (attribution: string): boolean => {
  if (attribution.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isDesignationValid = (designation: string): boolean => {
  if (designation.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isPhoneValid = (phone: string): boolean => {
  if (phone.length == 0) {
    return false;
  } else {
    return true;
  }
};

export const isEmailValid = (email: string): boolean => {
  if (email.length == 0) {
    return false;
  } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const isValidDonation = (
  donation: Donation,
  settings: Settings
): boolean => {
  if (!isDonationAmountValid(donation.amount, settings.donationMinimum)) {
    return false;
  }
  if (donation.instructions.type.length > 0) {
    if (
      (donation.instructions.type.indexOf("Paper") >= 0 ||
        donation.instructions.type.indexOf("Attribution") >= 0 ||
        donation.instructions.type.indexOf("Designation") >= 0 ||
        donation.instructions.type.indexOf("Note") >= 0) &&
      (!isNameValid(donation.instructions.contact.name) ||
        !isPhoneValid(donation.instructions.contact.phone) ||
        !isEmailValid(donation.instructions.contact.email))
    ) {
      return false;
    }
    if (
      donation.instructions.type.indexOf("OptIn") >= 0 &&
      (!isNameValid(donation.instructions.optIn.name) ||
        !isEmailValid(donation.instructions.optIn.email))
    ) {
      return false;
    }
    if (
      donation.instructions.type.indexOf("Paper") >= 0 &&
      (!isNameValid(donation.instructions.address.name) ||
        !isStreetValid(donation.instructions.address.street) ||
        !isCityValid(donation.instructions.address.city) ||
        !isStateValid(donation.instructions.address.state) ||
        !isZipValid(donation.instructions.address.zip))
    ) {
      return false;
    }
    if (
      donation.instructions.type.indexOf("Attribution") >= 0 &&
      !isAttributionValid(donation.instructions.attribution)
    ) {
      return false;
    }
    if (
      donation.instructions.type.indexOf("Designation") >= 0 &&
      !isDesignationValid(donation.instructions.designation)
    ) {
      return false;
    }
  }
  return true;
};
