import { Donation } from "../types";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 2,
});

export const calculateCurrentDonationAmount = (
  donations: Donation[],
  ein: string
): number => {
  let currentDonationAmount = 0;
  donations.map((donation) => {
    if (donation.ein != ein) {
      currentDonationAmount += donation.amount;
    }
  });
  return currentDonationAmount;
};

export const convertToCurrency = (amount: number): string => {
  let result = "$0.00";
  if (Math.round((amount + Number.EPSILON) * 100) / 100 != 0) {
    result = currencyFormatter.format(amount);
  }
  return result;
};
