export type Settings = {
  title: string;
  description: string;
  allowSearch: boolean;
  supportNDARequired: boolean;
  supportEmail: string;
  supportPhone: string;
  restrictSelection: boolean;
  searchResultsUrl: string;
  defaultUrl: string;
  cardValueMinimumOptIn: number;
  cardValueMinimumAttribution: number;
  cardValueMinimumDesignation: number;
  cardValueMinimumPaper: number;
  cardValueMinimumNote: number;
  allowedInstructions: string[];
  allowPartialSpend: boolean;
  donationDefault: number;
  donationMinimum: number;
  optOutOfShareSelections: boolean;
  captureThankYouMessage: boolean;
  addCharitiesLinkUrl: string;
  addCharitiesLinkText: string;
  causeCollectionId: string;
  defaultCardImageUrl: string;
  enableTestimonials: boolean;
  welcomeDetails: {
    cardSkip: boolean;
    cardTitle: string;
    displayCardTitle: boolean;
    displayCardImage: boolean;
    displayCardFrom: boolean;
    displayCardTo: boolean;
    displayCardMessage: boolean;
    welcomeSkip: boolean;
    welcomeTitle: string;
    markup: string;
    redirectUrl: string;
  };
  confirmationDetails: {
    confirmationSkip: boolean;
    displayCardImage: boolean;
    displaySelectedCharities: boolean;
    title: string;
    markup: string;
    redirectUrl: string;
  };
  thankYouDetails: {
    markup: string;
  };
  emailDetails: {
    emailCardSubject: string;
    emailCardTemplateUrl: string;
    emailSpenderNotificationSubject: string;
    emailSpenderNotificationTemplateUrl: string;
  };
};

export const DefaultSettings: Settings = {
  title: "",
  description: "",
  allowSearch: false,
  supportNDARequired: false,
  supportEmail: "",
  supportPhone: "",
  restrictSelection: false,
  searchResultsUrl: "",
  defaultUrl: "",
  cardValueMinimumOptIn: 0,
  cardValueMinimumAttribution: 0,
  cardValueMinimumDesignation: 0,
  cardValueMinimumPaper: 0,
  cardValueMinimumNote: 0,
  allowedInstructions: [],
  allowPartialSpend: true,
  donationDefault: 10,
  donationMinimum: 5,
  optOutOfShareSelections: true,
  captureThankYouMessage: true,
  addCharitiesLinkUrl: "/charities/",
  addCharitiesLinkText: "Add Charities",
  causeCollectionId: "00000000-0000-0000-0000-000000000000",
  defaultCardImageUrl: "",
  enableTestimonials: true,
  welcomeDetails: {
    cardSkip: true,
    cardTitle: "Your TisBest Charity Gift Card",
    displayCardTitle: true,
    displayCardImage: false,
    displayCardFrom: false,
    displayCardTo: false,
    displayCardMessage: false,
    welcomeSkip: true,
    welcomeTitle: "",
    markup: "",
    redirectUrl: "",
  },
  confirmationDetails: {
    confirmationSkip: true,
    displayCardImage: false,
    displaySelectedCharities: false,
    title: "",
    markup: "",
    redirectUrl: "",
  },
  thankYouDetails: {
    markup: "",
  },
  emailDetails: {
    emailCardSubject: "",
    emailCardTemplateUrl: "",
    emailSpenderNotificationSubject: "",
    emailSpenderNotificationTemplateUrl: "",
  },
};
