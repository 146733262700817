import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress,
  Grid,
} from "@mui/material";
import { Settings, CodeDetails } from "../../../shared/types";
import { getCardImage } from "../../../shared/utilities/actions";
import * as consts from "../../../shared/utilities/consts";

export interface CardDialogProps {
  settings: Settings;
  codeDetails: CodeDetails;
  busy: boolean;
  mobile: boolean;
  handleContinue: () => void;
}

export const CardDialog = ({
  settings,
  codeDetails,
  busy,
  mobile,
  handleContinue,
}: CardDialogProps): JSX.Element => {
  const [src, SetSrc] = useState<Blob | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (codeDetails?.code) {
        let srcResponse: Blob = await getCardImage(codeDetails.code);
        SetSrc(srcResponse);
      }
    };
    fetchData();
  }, [codeDetails]);

  return (
    <Dialog open={true} onClose={handleContinue} fullScreen={mobile}>
      <DialogTitle>
        {settings.welcomeDetails.displayCardTitle && (
          <div style={{ paddingTop: "20px" }}>
            {settings.welcomeDetails.cardTitle}
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={1}>
          {settings.welcomeDetails.displayCardImage && (
            <Grid item xs={12} sx={{ marginBottom: mobile ? "16px" : "24px" }}>
              {src ? (
                <img
                  src={URL.createObjectURL(src)}
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    margin: "auto",
                    border: "1px solid rgb(242 242 242)",
                    borderRadius: "22px",
                    backgroundColor: "rgb(242 242 242)",
                  }}
                />
              ) : (
                <img
                  src={`${consts.BLOB_URL}/v4-system-assets/CardImagePlaceholder.gif`}
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    margin: "auto",
                    border: "1px solid rgb(242 242 242)",
                    borderRadius: "22px",
                    backgroundColor: "rgb(242 242 242)",
                  }}
                />
              )}
            </Grid>
          )}
          {settings.welcomeDetails.displayCardTo && codeDetails.cardTo && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ paddingLeft: mobile ? "8px" : "24px" }}
              >
                A Gift for {codeDetails.cardTo}
              </Typography>
            </Grid>
          )}
          {settings.welcomeDetails.displayCardFrom && codeDetails.cardFrom && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ paddingLeft: mobile ? "8px" : "24px" }}
              >
                From {codeDetails.cardFrom}
              </Typography>
            </Grid>
          )}
          {settings.welcomeDetails.displayCardMessage && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ paddingLeft: mobile ? "8px" : "24px" }}
                component="div"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: codeDetails.cardMessage,
                  }}
                />
              </Typography>
            </Grid>
          )}
          {mobile && (
            <Grid item xs={12}>
              <Button
                onClick={handleContinue}
                disabled={busy}
                fullWidth={true}
                style={{ marginTop: "24px" }}
              >
                Continue
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!mobile && (
        <DialogActions sx={{ paddingRight: "24px", paddingBottom: "24px" }}>
          <Button onClick={handleContinue} disabled={busy}>
            Continue
          </Button>
        </DialogActions>
      )}
      {busy && <LinearProgress />}
    </Dialog>
  );
};
