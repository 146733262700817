import React from "react"; // eslint-disable-line no-unused-vars
import {
  ThemeProvider,
  ScopedCssBaseline,
  createTheme,
  Container,
} from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";
import { light } from "../../../shared/styles";
import { Main } from "./";
import { styled } from "@mui/system";

export interface AppProps {}

const lightTheme = createTheme(light as ThemeOptions);

const StyledContainer = styled(Container)(({ theme }) => ({}));

export const App = ({}: AppProps): JSX.Element => {
  return (
    <ScopedCssBaseline>
      <ThemeProvider theme={lightTheme}>
        <StyledContainer disableGutters={true} maxWidth={false}>
          <Main />
        </StyledContainer>
      </ThemeProvider>
    </ScopedCssBaseline>
  );
};
