import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Notification,
  CodeDialog,
  WelcomeDialog,
  CardDialog,
  SpentDialog,
} from "../components";
import {
  getSettings,
  fetchCodeDetails,
  validateCode,
  clearCookies,
} from "../../../shared/utilities/actions";
import * as consts from "../../../shared/utilities/consts";
import {
  CodeDetails,
  DefaultCodeDetails,
  Settings,
  DefaultSettings,
} from "../../../shared/types";
import { useHash } from "../../../shared/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface MainProps {}

export const Main = (props: MainProps): JSX.Element => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [hash, setHash] = useHash();
  const [codeDetails, setCodeDetails] = useState<CodeDetails>({
    ...DefaultCodeDetails,
  });
  const [settings, setSettings] = useState<Settings>({
    ...DefaultSettings,
  });

  const [allowRedirect, setAllowRedirect] = useState<boolean>(true);
  const [busy, setBusy] = useState<boolean>(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      let params = new URLSearchParams(document.location.search);
      let queryParameterCode = params.get("code");
      if (queryParameterCode && queryParameterCode.length == 7) {
        setHash("dialog-code");
      } else {
        let themeId = fetchCodeDetails(codeDetails.themeId, setCodeDetails);
        let settingsResponse: Settings = await getSettings(themeId);
        setSettings(settingsResponse);
      }
      setBusy(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    switch (hash) {
      case "#dialog-donate":
        setAllowRedirect(false);
        setBusy(false);
        break;
      case "#dialog-code":
        let params = new URLSearchParams(document.location.search);
        let queryParameterCode = params.get("code");
        let queryParameterEmail = params.get("email");
        let queryParameterName = params.get("title");
        if (queryParameterCode && queryParameterCode.length == 7) {
          setCodeDetails({
            ...codeDetails,
            code: queryParameterCode.toUpperCase(),
            email: queryParameterEmail ? queryParameterEmail : "",
            name: queryParameterName ? queryParameterName : "",
          });
        }
        setBusy(false);
        break;
      case "#dialog-card":
      case "#dialog-welcome":
        if (codeDetails.valid) {
          setBusy(false);
        } else {
          setHash("dialog-code");
        }
        break;
      case "#dialog-spent":
        setBusy(false);
        break;
      default:
        setAllowRedirect(true);
        setBusy(true);
        break;
    }
  }, [hash]);

  const submitCode = async (updatedCodeDetails: CodeDetails) => {
    setBusy(true);
    let validatedCodeDetails = await validateCode(updatedCodeDetails);
    if (validatedCodeDetails.valid) {
      setCodeDetails(validatedCodeDetails);
      let settingsResult = await getSettings(validatedCodeDetails.themeId);
      setSettings(settingsResult);
      setNotification({
        message: `Code ${validatedCodeDetails.code} Validated`,
        type: consts.ALERT_TYPE_SUCCESS,
      });
      if (validatedCodeDetails.availableAmount > 0) {
        if (settingsResult.welcomeDetails.cardSkip) {
          if (settingsResult.welcomeDetails.welcomeSkip) {
            redirect();
          } else {
            setHash("dialog-welcome");
          }
        } else {
          setHash("dialog-card");
        }
      } else {
        setHash("dialog-spent");
      }
    } else {
      setNotification({
        message: validatedCodeDetails.message,
        type: consts.ALERT_TYPE_ERROR,
      });
      setBusy(false);
    }
  };

  const redirect = async () => {
    setBusy(true);
    if (allowRedirect && settings.welcomeDetails.redirectUrl) {
      if (codeDetails.availableAmount > 0) {
        window.location.href = settings.welcomeDetails.redirectUrl;
      } else {
        window.location.href = settings.confirmationDetails.redirectUrl;
      }
    } else {
      let searchParams = new URLSearchParams(document.location.search);
      searchParams.delete("dialog-code");
      searchParams.delete("dialog-email");
      window.location.search = searchParams.toString();
      setHash("");
      window.location.reload();
    }
  };

  const reset = async () => {
    setBusy(true);
    setHash("");
  };

  const renderDialog = (dialog: string) => {
    switch (dialog) {
      case "#dialog-code":
      case "#dialog-donate":
        return (
          <CodeDialog
            busy={busy}
            mobile={mobile}
            codeDetails={codeDetails}
            handleContinue={async (updatedCodeDetails: CodeDetails) => {
              await submitCode(updatedCodeDetails);
            }}
            handleClose={async () => {
              await reset();
            }}
          />
        );
      case "#dialog-card":
        return (
          <CardDialog
            busy={busy}
            mobile={mobile}
            codeDetails={codeDetails}
            settings={settings}
            handleContinue={() => {
              setBusy(true);
              if (settings.welcomeDetails.welcomeSkip) {
                redirect();
              } else {
                setHash("dialog-welcome");
              }
            }}
          />
        );
      case "#dialog-welcome":
        return (
          <WelcomeDialog
            busy={busy}
            mobile={mobile}
            codeDetails={codeDetails}
            settings={settings}
            handleContinue={async () => {
              await redirect();
            }}
          />
        );
      case "#dialog-spent":
        return (
          <SpentDialog
            busy={busy}
            mobile={mobile}
            codeDetails={codeDetails}
            settings={settings}
            handleContinue={async () => {
              setBusy(true);
              await clearCookies();
              let searchParams = new URLSearchParams(document.location.search);
              searchParams.delete("dialog-code");
              searchParams.delete("dialog-email");
              window.location.search = searchParams.toString();
              setHash("");
              window.location.reload();
              //setHash("dialog-card");
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Notification
        message={notification.message}
        type={notification.type}
        open={notification.message.length > 0}
        onDismiss={() => {
          setNotification({ message: "", type: "" });
        }}
      />
      {renderDialog(hash)}
    </React.Fragment>
  );
};
