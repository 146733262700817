import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Grid,
} from "@mui/material";
import { Settings, CodeDetails } from "../../../shared/types";

export interface WelcomeDialogProps {
  settings: Settings;
  codeDetails: CodeDetails;
  busy: boolean;
  mobile: boolean;
  handleContinue: () => void;
}

export const WelcomeDialog = ({
  settings,
  codeDetails,
  busy,
  mobile,
  handleContinue,
}: WelcomeDialogProps): JSX.Element => {
  return (
    <Dialog open={true} onClose={handleContinue} fullScreen={mobile}>
      <DialogTitle>
        <div
          style={{ paddingTop: "20px" }}
        >{`${settings.welcomeDetails.welcomeTitle}`}</div>
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: settings.welcomeDetails.markup,
              }}
            />
          </Grid>
          {mobile && (
            <Grid item xs={12}>
              <Button
                onClick={handleContinue}
                disabled={busy}
                fullWidth={true}
                style={{ marginTop: "24px" }}
              >
                Continue
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!mobile && (
        <DialogActions sx={{ paddingRight: "24px", paddingBottom: "24px" }}>
          <Button onClick={handleContinue} disabled={busy}>
            Continue
          </Button>
        </DialogActions>
      )}
      {busy && <LinearProgress />}
    </Dialog>
  );
};
