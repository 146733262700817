import React, { useState } from "react"; // eslint-disable-line no-unused-vars
import { TextField } from "@mui/material";

export interface RequiredTextFieldProps {
  disabled: boolean;
  label: string;
  autoComplete?: string;
  helperText?: string;
  value: string;
  variant: "dialog" | "paper";
  test: (value: string) => string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RequiredTextField = ({
  disabled,
  label,
  autoComplete,
  helperText,
  value,
  variant,
  test,
  onChange,
}: RequiredTextFieldProps): JSX.Element | null => {
  const [hadFocus, setHadFocus] = useState<boolean>(false);
  let errorMessage = hadFocus ? test(value) : "";
  let className = "";
  switch (variant) {
    case "dialog":
      className = "tb_dialog_input";
      break;
    case "paper":
      className = "tb_paper_input";
      break;
  }
  return (
    <TextField
      autoComplete={autoComplete}
      disabled={disabled}
      required={true}
      fullWidth={true}
      InputProps={{
        className: className,
      }}
      label={label}
      helperText={
        errorMessage.length > 0 ? errorMessage : helperText ? helperText : " "
      }
      error={errorMessage.length > 0}
      value={value}
      onChange={onChange}
      onBlur={(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setHadFocus(true);
      }}
    />
  );
};
